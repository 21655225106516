"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var ScrollTrigger_1 = require("gsap/ScrollTrigger");
require("../scss/style.scss");
//console.log('TypeScript 変換成功');
//hello();
/* =================================================== */
// 横スクロール
/* =================================================== */
window.addEventListener('load', function () {
    gsap_1.gsap.registerPlugin(ScrollTrigger_1.ScrollTrigger);
    var top = document.querySelector('#top');
    var topWidth = top.offsetWidth;
    var about = document.querySelector('#about');
    var aboutWidth = about.offsetWidth;
    /*
    const history = document.querySelector('#history') as HTMLElement | null;
    const historyWidth = history.offsetWidth;
    */
    /*
    const appreciation = document.querySelector('#appreciation') as HTMLElement | null;
    const appreciationWidth = appreciation.offsetWidth;
    */
    var fno = document.querySelector('#fno');
    var fnoWidth = fno.offsetWidth;
    var coming = document.querySelector('#coming');
    var comingWidth = coming.offsetWidth;
    var footer = document.querySelector('#footer');
    var footerWidth = footer.offsetWidth;
    // gsap.registerPlugin(ScrollTrigger); // npm/yarnの際に必要
    var panels = gsap_1.gsap.utils.toArray('.panel');
    var wrapperWidth = topWidth + aboutWidth + fnoWidth + comingWidth + footerWidth;
    var deviceWidth = window.innerWidth;
    /**
     * 横スクロール開始
     */
    gsap_1.gsap.to(panels, {
        x: -(wrapperWidth - deviceWidth),
        ease: 'none',
        scrollTrigger: {
            // scrollTrigger
            trigger: '#wrapper',
            pin: true,
            scrub: 1,
            start: 'top, left',
            end: function () { return '+=' + wrapperWidth; },
            invalidateOnRefresh: true,
            anticipatePin: 1,
        },
    });
});
